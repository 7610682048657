@import "~@elementor/eds-styles/lib/mixins";

// FONT FACE: @fontface declartion for 'eds' custom fonts setup
// arguments:
// - $font-map: scss map, see 'settings/fonts.scss' for example
// -- mixin iterates on map setting to produce all font instance variations.
// - $custom-font-path, enables overriding paths issues
@mixin define-font-face($font-map, $custom-font-path: null) {
	@if $custom-font-path {
		$font-path: $custom-font-path;
	} @else {
		$font-path: $font-path;
	}

	$family: map-deep-get($font-map, family);
	$name: map-deep-get($font-map, name);
	$src: $font-path + $name + "/" + $name;

	@each $font, $value in map-deep-get($font-map, fonts) {
		$weight: map-deep-get($font-map, fonts, $font, weight);
		$suffix: map-deep-get($font-map, fonts, $font, suffix);
		$style: map-deep-get($font-map, fonts, $font, style);
		@font-face {
			font-family: "#{$family}";
			font-style: $style;
			font-weight: $weight;
			src: url("#{$src}-#{$suffix}.woff2") format("woff2"), url("#{$src}-#{$suffix}.woff") format("woff");
		}
	}
}
