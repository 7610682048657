// # CLOUD PASSWORD LOCK: Variables, Custom overrides

// Global definitions
$font-path:"../assets/fonts/";

$font-family-base: $eds-font-family-mkt;

// btn
$btn-border-radius: 1;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

// Input
$input-border-radius: 1;
$input-border-radius-lg: $input-border-radius;
$input-border-radius-sm: $input-border-radius;
