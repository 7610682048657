// # CLOUD PASSWORD LOCK: BASE, Atoms
// Local common Building blocks definitions
$cpl-lock-title-font-size: $eds-font-size-32;
$cpl-lock-title-font-size-media-up-md: $display2-font-size-media-up-md;
$cpl-lock-title-margin-y: map-get($spacers, 3);

.cpl-lock-title {
  white-space: pre-line;
  font-size: $cpl-lock-title-font-size;
  margin-top: $cpl-lock-title-margin-y;
  margin-bottom: $cpl-lock-title-margin-y;

  @include media-breakpoint-up(md) {
    font-size: $cpl-lock-title-font-size-media-up-md;
  }

  @include media-breakpoint-up(xl) {
    text-align: left;
  }
}

.cpl-w-content {
  @include media-breakpoint-up(xl) {
    width: 85% !important;
  }

  @include media-breakpoint-up(xxl) {
    width: 75% !important;
  }
}

.cpl-img-fluid {
  @include media-breakpoint-up(xl) {
    max-width: fit-content !important;
  }
}

.cpl-left-side {
  @include media-breakpoint-up(xl) {
    padding-right: 4rem !important; // $eds-spacing-layout-3
  }
}
